import { Injectable } from '@angular/core';
import { DatabaseService } from '../database/database.service';
import { LoginIntention } from 'src/app/models/monitoreo/login_intention.model';
import { LoginIntentionCustom } from 'src/app/views/layout/navbar/navbar.component';

@Injectable({
  providedIn: 'root'
})
export class ActivateSaleService {

  constructor(private databaseService: DatabaseService) { }

  async getSecurityLogin(celphone: number): Promise<{ ok:boolean, data:LoginIntention[]}> {
    return this.databaseService.get(`/security/login-intentions/requests`, { celphone });
  }

  async activateLogin(id: number): Promise<any> {
    return await this.databaseService.put(`/security/login-intentions/approve`,{id});
   
  }

  async getLoginIntentions(): Promise<any> {
    const data = await this.databaseService.get(`/security/login-intentions/report-panel`);
    return data;
  }
}
