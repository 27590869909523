import { Injectable } from '@angular/core';
import { SalesService } from '../sales/sales.service';
import { Router } from '@angular/router';
import { CollectionTableInterface } from 'src/app/models/specials/collection_table.model';

@Injectable({
  providedIn: 'root'
})
export class DalaysSalesService {

  constructor(
    private salesService: SalesService,
    private router: Router,
  ) { }

  // Genera la tabla de atrazos
  async getTabDelaysSale(id: number): Promise<any> {

    const data: {
      tab: any,
      total_atrazo: number,
      total_estimado: number,
    }[] = [];
    const tab = await (await this.salesService.tablePaymentsDetail(id)).reverse(); // Tabla de cobranza
    const total_atrazo = tab[0].total_estimado - tab[0].total_abonos - tab[0].payment_week; // Calcula el saldo de atrazo
    const total_estimado = tab[0].total_estimado; // Calcula el total que deberia llevar pagado

    data.push({
      tab,
      total_atrazo,
      total_estimado,
    });

    return data;
  }
}
