
export const environment = {
    production: false,
    name:'Development',
    baseUrl: `https://lnxawsapi.sandbox.swisscrown.com.mx/api`,
    pdfServiceBaseurl: `http://lnxawslbd.vertex.swisscrown.com.mx`,
    checkerBaseUrl: 'https://lnx.services.self-hosted.checker.swisscrown.com.mx/api', // DigitalOcean checador
    ws: 'https://lnxawsapi.sandbox.swisscrown.com.mx/',
    gpsWs: 'https://lnxawsp.gps.swisscrown.com.mx',
};


// const localHost = 'localhost';
// const pacoServer = '192.168.137.1';

// export const environment = {
//   production: false,
//   name: 'Development',
//   baseUrl: `http://${localHost}:9089/api`,
//   ws: `http://${localHost}:9089/`,
//   gpsWs: 'https://lnxawsp.gps.swisscrown.com.mx',
//   ws: 'http://137.184.104.47:9089/',
//   baseUrl: 'http://swiss.int.crownservices.host/api'
//   checkerBaseUrl: `http://${localHost}:1466/api`, // checador
// };

  //ng s --configuration dev
  //ng build --configuration dev
