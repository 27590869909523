import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'error',
        component: ErrorPageComponent,
      },
      {
        path: 'error/:type',
        component: ErrorPageComponent,
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'trabajador',
        loadChildren: () => import('./views/pages/trabajador/trabajador.module').then(m => m.TrabajadorModule)
      },
      {
        path: 'twilio',
        loadChildren: () => import('./views/pages/twilio/twilio.module').then(m => m.TwilioModule)
      },
      {
        path: 'sistemas',
        loadChildren: () => import('./views/pages/sistemas/sistemas.module').then(m => m.sistemasModule)
      },
      {
        path: 'warehouse',
        loadChildren: () => import('./views/pages/warehouse/warehouse.module').then(m => m.WarehouseModule)
      },
      {
        path: 'sales',
        loadChildren: () => import('./views/pages/sales/sales.module').then(m => m.SalesModule)
      },
      {
        path: 'group',
        loadChildren: () => import('./views/pages/group/group.module').then(m => m.GroupModule)
      },
      {
        path: 'monitoring',
        loadChildren: () => import('./views/pages/monitoring/monitoring.module').then(m => m.monitoringModule)
      },
      {
        path: 'sucursales',
        loadChildren: () => import('./views/pages/sucursales/sucursales.module').then(m => m.SucursalesModule)
      },
      {
        path: 'administrative',
        loadChildren: () => import('./views/pages/administrative/administrative.module').then(m => m.AdministrativeModule)
      },
      {
        path: 'apps',//modulo referencia
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'accounting',
        loadChildren: () => import('./views/pages/accounting/accounting.module').then(m => m.AccountingModule)
      },
      {
        path: 'franchises',
        loadChildren: () => import('./views/pages/franchises/franchises.module').then(m => m.FranchisesModule)
      },
      {
        path: 'collectors',
        loadChildren: () => import('./views/pages/collectors/collectors.module').then(m => m.CollectorsModule)
      },
      {
        path: 'mechanic',
        loadChildren: () => import('./views/pages/mechanic/mechanic.module').then(m => m.MechanicModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  // {
  //   path: 'error',
  //   component: ErrorPageComponent,
  // },
  // {
  //   path: 'error/:type',
  //   component: ErrorPageComponent,
  // },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
