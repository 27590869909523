import Swal, { SweetAlertIcon } from 'sweetalert2';

export const toast = (title: string, icon: SweetAlertIcon, duration?: number, position?: any, background?: string) => {

  if(icon == "error"){
    background = '#f27474';
    duration = 10000;
  }

  if(icon == "info"){
    background = '#9DE0F6';
    duration = 10000;
  }

  Swal.fire({
    title: "<h5 style='color:#FFFFFF;'>" + title + "</h5>",
    icon,
    toast: true,
    position: position || 'bottom-end', // Posición
    timer: duration || 5000, // Duración
    showConfirmButton: false,
    background: background || "#a5dc86", // Color de fondo
    iconColor: "#ffffff",
  });
}

export const respons = (resp: any) => {
  if (resp) {
    toast('Bien [' + resp + ']', 'success');
  } else {
    toast('Error con el servidor [' + resp + ']', 'error');
  }
}

export const loading = (duration: any) => {
    toast('Cargando', 'info', duration, 'center', '#3a52c8');
}
