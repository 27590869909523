import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { WebsocketService } from './services/websockets/websocket.service';
import { DirectionService } from './services/clientes/directions.service';
import { ReportService } from './services/sistemas/servicio.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  sosSuscription: Subscription | undefined;
  constructor(
    public webSocketsService: WebsocketService,
    private directonService: DirectionService,
    private reportService: ReportService,
    private router: Router,
    private title: Title,
  ) {
    title.setTitle('Inicio - Panel De Control (Swiss Crown)');
  }

  async ngOnInit(): Promise<void> {
    this.sosSuscription = this.webSocketsService.listen('SOS').subscribe(async (room: any) => {

      let affiliates = [];
      let nameAffiliates = '';
      const contract = await this.reportService.findOneContract(room.contractId);
      for (const a of room.affiliates) {
        // let affiliate = await this.afiliadoService.getSelectAffiliate(a)
        // affiliates.push(affiliate.name);
        // nameAffiliates = nameAffiliates +' , '+ affiliate.name
      }
      const { value: accept } = await Swal.fire({
        title: 'SOS',
        text: `Alerta emitida desde el servicio no. ${contract.id}  . \nAfiliados: ${nameAffiliates}.\nUsuario: ${contract.direction.user.name}`,
        imageUrl: '/assets/images/logo-escudo.jpeg',
        imageAlt: 'Custom image',
      });
      if (accept) {
        this.router.navigateByUrl('/monitoreo/sos-new')
      }
      nameAffiliates = '';
      affiliates = []
    });

    // this.notificarme();

  }

  notificarme() {
    if (!window.Notification) {

      return
    }

    if (Notification.permission === 'granted') {
      // new Notification('Hola Mundo! - granted');
      this.enviarNotificacion();
    } else if (Notification.permission !== 'denied') {
      // || Notification.permission === 'default'
      Notification.requestPermission(function (permission) {

        if (permission === 'granted') {
          new Notification('Hola Mundo! - pregunta');
          this.enviarNotificacion();
        }
      });
    }
  }

  enviarNotificacion() {
    const notificationOpts = {
      body: 'Este es el cuerpo de la notificación',
      icon: 'img/icons/icon-72x72.png'
    };
    const n = new Notification('Hola Mundo', notificationOpts);
    n.onclick = () => {

    };
  }
}
