import { Injectable } from '@angular/core';
import { DatabaseService } from '../database/database.service';
import { PermissionsInterface } from 'src/app/models/trabajador/permissions.model';
import { HttpClient } from '@angular/common/http';
import { toast } from 'src/app/functions/notifications';
import { filter, map } from 'rxjs/operators'
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  constructor(
    private databaseService: DatabaseService,
    private http: HttpClient,
    private router: Router
  ) {
  }

  // Obtiene los permisos del empleado seleccionado
  async getSelect(id: any): Promise<PermissionsInterface[]> {
    const data = await this.databaseService.get(`/permissions/user/${id}`);
    return data.data;
  }

  getMenu(id: number) {
    return this.http.get(`${this.databaseService.url}/menus/permissions/${id}`).pipe(map((data: any) => {
      return data['data'];
    }))
  }

  // Asigna todos los permisos
  async getAllPermissions(id: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.get(`/permissions/create/${id}`);
      return resp.data;
    } catch (error) {
      console.error('Error de peticion');
    }
    return false;
  }

  getMenus(ids: number[]) {
    return this.http.post(`${this.databaseService.url}/menus/permissions/rep`, ids).pipe(map((data: any) => {
      return data['data'];
    }))
  }

  // Elimina el permiso seleccionado (Individual)
  async delete(id: string): Promise<boolean> {
    const data = await this.databaseService.delete(`/permissions/${id}`);
    return data.data;
  }

  // Elimina todos los permisos del empleado seleccionado
  async deleteAllPermissions(id: string): Promise<boolean> {
    console.log("Reiniciando los permisos");
    const data = await this.databaseService.get(`/permissions/delete/all/user/${id}`);
    return data.data;
  }

  async post(id: number, data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post(`/permissions/${id}`, data);
      return resp.data;
    } catch (error) {
      console.error('Error de peticion');
    }
    return false;
  }

  getMenusRutas(sessionId: number) {
    return this.http.get(`${this.databaseService.url}/menus/permissions/rep/${sessionId}`).pipe(map((data: any) => {
      return data['data'];
    })).toPromise();
  }

  getMenusRutasSidebar(sessionId: number) {
    return this.http.get(`${this.databaseService.url}/menus/sidebar/${sessionId}`).pipe(map((data: any) => {
      return data['data'];
    })).toPromise();
  }

  getMenusRuta(sessionId: number, id: number) {
    let val = false;
    return this.http.get(`${this.databaseService.url}/menus/permissions/rep/${sessionId}`).pipe(map((data: any) => {
      data['data'].find((ruta: any) => {
        if (ruta.id == id) {
          val = true;
          return ruta
        }
      });

      if (val == false) {
        toast('Acceso denegado [' + val + ']', 'error');
        console.log("acceso denegado " + val);
      }

      return val
    }));
  }

  // Seguridad de los modulos
  getMenusRutaModulos(sessionId: number, id: number) {
    let val = false;
    const jsonData = JSON.parse(localStorage.getItem('menuArbolSwiss'));
    const idExists = this.searchId(jsonData, id);

    if (idExists) {
      val = true;
      return val
    } else {
      if (val == false) {
        toast('Acceso denegado [' + val + ']', 'error');
        this.router.navigate(['/dashboard'])
      }

      return val
    }
  }

  // Valida si el empleado esta activo y si no le cierra sessión
  async CleanSession(sessionId: number) {
    const data = await this.databaseService.get(`/employees/validation/status/${sessionId}`);
    
    if (data.data.status == 0) {
      toast('Fuiste Banneado', 'error');

      localStorage.removeItem('token');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('name');
      localStorage.removeItem('surname');
      localStorage.removeItem('email');
      localStorage.removeItem('photo');
      localStorage.removeItem('menuArbolSwiss');

      this.router.navigate(['/']);
    }

  }

  // Verificar si un permiso esta en el json del menu
  searchId(data: any[], idToFind: number): boolean {
    for (const item of data) {
      if (item.id === idToFind) {
        return true;
      }

      if (item.subItems && item.subItems.length > 0) {
        if (this.searchId(item.subItems, idToFind)) {
          return true;
        }
      }
    }

    return false;
  }

}