import { Injectable } from '@angular/core';
import { DatabaseService } from '../database/database.service';
import { SalesInterface, TrackerMapSaleInterface } from '../../models/sales/sales.model';
import { ClientInterface } from 'src/app/models/clientes/clients.model';
import { ContractInterface } from 'src/app/models/sales/contract.model';
import { SalesDetail } from 'src/app/models/sales/sales_detail.model';
import format from 'date-fns/format'
import subDays from 'date-fns/subDays'
import addDays from 'date-fns/addDays'
import parseJSON from 'date-fns/parseJSON'
import * as moment from 'moment';
import { PaymethodInterface } from 'src/app/models/sales/paymethod.model';
import { RaitingInterface } from 'src/app/models/raiting.model';
import { InterestsInterface } from 'src/app/models/sales/interests.model';
import { SalesDetailsInterface } from 'src/app/models/especiales/sales/sales_details.model';
import { TabPaymentsInterface } from 'src/app/models/sales/detalle_crediticio/table_payments.model';
import { VerificationHistoryInterface } from 'src/app/models/sales/detalle_crediticio/verification_history.model';
import { TotalOfSaleInterface } from 'src/app/models/sales/detalle_crediticio/total_of_sale.model';
import { PaymentInterface } from 'src/app/models/sales/payments.model';
import { FranchiseInformations } from 'src/app/models/sales/franchise_informations.model';
import { OxxoReferenceModel } from 'src/app/models/sales/oxxo_reference.model';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(
    private databaseService: DatabaseService
  ) { }

  async getAll(): Promise<SalesInterface[]> {
    const data = await this.databaseService.get('/sales');
    return data.data;
  }

  // Totas las ventas de un cliente
  async getAllsalesClient(id: number): Promise<any[]> {
    const data = await this.databaseService.get(`/all/sales/client/${id}`);
    return data.data;
  }

  // Obtine los datos de la venta franquisiada
  async getSalesFranchiseInformations(id: number): Promise<FranchiseInformations> {
    const data = await this.databaseService.get(`/sales/franchise_informations/${id}`);
    return data.data;
  }

  // Saber el status de una venta
  async getSalesPaymentStatus(id: number, amount: number): Promise<any> {
    const data = await this.databaseService.get(`/sales/pay/status/${id}/${amount}`);
    return data.data;
  }

  // Ventas pendientes
  async realseSales(ids: number[]): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/realse/sales/pending/${ids}`);
    return data.data;
  }

  // Obtiene todas las ventas y las pagina
  async getAllSalespag(limit, page = 1): Promise<{ count: number, data: SalesInterface[] }> {
    const data = await this.databaseService.getAndCount('/sales', limit, page);
    return data;
  }

  // Obtiene todas las ventas y las pagina (por fecha)
  async getAllSalesDatepag(limit, page = 1, start: Date, end: Date): Promise<{ count: number, data: SalesInterface[] }> {
    const data = await this.databaseService.getAndCount(`/sales/all/date/?start=${start}&end=${end}&`, limit, page);
    return data;
  }

  // Obtiene todas las ventas del empleado seleccionado
  async getAllSalesEmployeepag(limit, page = 1, id: number, start: Date, end: Date): Promise<{ count: number, data: SalesInterface[] }> {
    const data = await this.databaseService.getAndCount(`/sales/employee/${id}/?start=${start}&end=${end}&`, limit, page);
    return data;
  }

  // Obtiene todas las ventas del grupo seleccionado
  async getAllSalesGroupPag(limit, page = 1, id: number, start: Date, end: Date): Promise<{ count: number, data: SalesInterface[] }> {
    const data = await this.databaseService.getAndCount(`/sales/group/${id}/?start=${start}&end=${end}&`, limit, page);
    return data;
  }

  // Obtiene todas las ventas de la sucursal seleccionada
  async getAllSalesBranchPag(limit, page = 1, id: number, start: Date, end: Date): Promise<{ count: number, data: SalesInterface[] }> {
    const data = await this.databaseService.getAndCount(`/sales/branch/${id}/?start=${start}&end=${end}&`, limit, page);
    return data;
  }

  // Obtiene los datos de la venta seleccionada
  async getSelect(id: number): Promise<SalesInterface> {
    const data = await this.databaseService.get(`/sales/${id}`);
    return data.data;
  }

  async getSelectSuper(id: number, f1: any): Promise<SalesInterface[]> {
    // f1 = moment(f1, "YYYY-MM-DD").add(-1, 'days').format("YYYY-MM-DD");
    const f2 = moment(f1, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");

    let params = { employeeId: id, start: f1, end: f2 };
    const data = await this.databaseService.getWithParams(`/sales_supervisor`, params);
    return data.data;
  }

  // Inventario: obtiene el resumen de las ventas por grupo y por fecha
  async getTransportSales(id: number, f1: any): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/transport/sales/${id}/?f1=${f1}`);
    return data.data;
  }

  // Contador de las ventas del dia
  async getCountDay(): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/sales/cont_day`);
    return data.data;
  }

  // Contador de ventas de la semana
  async getCountWeek(): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/sales/cont_week`);
    return data.data;
  }

  // Contador de ventas de la semana de la sucursal
  async getCountWeekByBranch(id: any): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/sales/cont_week/${id}`);
    return data.data;
  }

  // Contador de las ventas del dia por sucursal
  async getCountDayForBranch(branchId: any): Promise<SalesInterface[]> {
    if (branchId == undefined || branchId == null) {
      branchId = localStorage.getItem('branch');
    }

    const data = await this.databaseService.get(`/sales/branch/cont_day/${branchId}`);
    return data.data;
  }

  // Contador de ventas de la semana por sucursal
  async getCountWeekForBranch(branchId: any): Promise<SalesInterface[]> {
    if (branchId == undefined || branchId == null) {
      branchId = localStorage.getItem('branch');
    }
    const data = await this.databaseService.get(`/sales/branch/cont_week/${branchId}`);
    return data.data;
  }

  async getSelectStatus(status: number, date1: any, date2: any): Promise<SalesInterface[]> {
    const data = await this.databaseService.get(`/sales/status/${status}/${date1}`);
    return data.data;
  }

  async getSelectClient(id: number): Promise<ClientInterface> {
    const data = await this.databaseService.get(`/sales/client/${id}`);
    return data.data;
  }

  // Busca el contrato y relaciona con la venta (si esta vendido)
  async getSelectContract(id: any): Promise<SalesInterface> {
    const data = await this.databaseService.get(`/sales/contract/${id}`);
    return data.data;
  }

  // Obtiene todos los intereses
  async getClientInterests(id: number): Promise<InterestsInterface[]> {
    const data = await this.databaseService.get(`/interests/sales/${id}`);
    return data.data;
  }


  // Obtiene los detalles de todas las ventas del detalle crediticio
  async getSaleDetails(clientId: number, code: number): Promise<SalesDetailsInterface[]> {
    const data = await this.databaseService.get(`/sale_detail/${clientId}/${code}`);
    return data.data;
  }

  // Obtiene la información del cliente apartir del codigo
  async getDataCliente(code: number): Promise<SalesInterface> {
    const data = await this.databaseService.get(`/data_client/sales/${code}`);
    return data.data;
  }

  //
  async getContractDev(id: number): Promise<SalesDetailsInterface[]> {
    const data = await this.databaseService.get(`/devolution/contract/${id}`);
    return data.data;
  }

  // Metodos de pago
  async getPaymethods(): Promise<PaymethodInterface[]> {
    const data = await this.databaseService.get(`/paymethods`);
    return data.data;
  }

  // Obligar al cobrador a pasar por el pago
  async compelTheCollector(id: number): Promise<PaymethodInterface[]> {
    const data = await this.databaseService.get(`/update/collector-required/${id}`);
    return data.data;
  }

  // Tabla de pagos (Detalle crediticio)
  async tablePaymentsDetail(id: number): Promise<TabPaymentsInterface[]> {
    const data = await this.databaseService.get(`/sales/total/${id}`);
    return data.data;
  }

  // Razones de cancelacion de una venta
  async reasonCancelSale(id: number): Promise<VerificationHistoryInterface[]> {
    const data = await this.databaseService.get(`/sales/cancel/reason/${id}`);
    return data.data;
  }

    // Cambiar la contraseña del cliente
    async getRequestPassword(id: number): Promise<any> {
      const data = await this.databaseService.get(`/get/change_client_password/${id}`);
      return data.data;
    }

  // Obtiene el total
  async getTotalOfSale(id: number): Promise<TotalOfSaleInterface> {
    const data = await this.databaseService.get(`/sales/get_total_of_sale/${id}`);
    return data.data;
  }

  // Obtiene todos los Ids de los grupos pertenecientes a una sucursal eleccionada
  async IdsGroupsBranches(id: number): Promise<TabPaymentsInterface[]> {
    const data = await this.databaseService.get(`/groups/branches/ids/${id}`);
    return data.data;
  }

  // Obtiene las ubicaciones las ventas que se envien
  async IdsSalesCoordinates(ids: number[], tipo: string): Promise< any[]> {
    if(ids.length == 0){ ids = [0]; }
    const data = await this.databaseService.get(`/location/sales/client?ids=${ids}`);
    return data.data;
  }

  // Obtiene el raiting de ventas del grupo
  async getSalesDetailRaitingGroup(start: any, end: any, now: any, branchId: any): Promise<RaitingInterface[]> {
    if (branchId == undefined || branchId == null) {
      branchId = localStorage.getItem('branch');
    }
    const data = await this.databaseService.get(`/sales_raiting/detail/?start=${start}&end=${end}&now=${now}&branchId=${branchId}`);
    return data.data;
  }

  //
  async post(data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post('/sales', data);
      return resp.data;
    } catch (error) {
      console.error('Ocurrio un error: ' + error);
    }
    return false;
  }

  // Genera los codigos antiguos
  async postContractsCodes(data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post('/create/stock_entry', data);
      return resp.data;
    } catch (error) {
      console.error('Ocurrio un error: ' + error);
    }
    return false;
  }

  // Genera los codigos nuevos de contratos
  async postContractsCodesNew(data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post('/create/stock_entry', data);
      return resp.data;
    } catch (error) {
      console.error('Ocurrio un error: ' + error);
    }
    return false;
  }

  // Capturar los datos del cliente con todo y venta
  async postSales(data: any): Promise<any> {
    try {
      const resp = await this.databaseService.post('/sale/data', data);
      return resp;
    } catch (error) {
      console.error('Ocurrio un error: ' + error);
    }
    return false;
  }

  // Inserta la foto de la venta
  async postImg(body: any, data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post('/sales_document/new', body);
      return resp.data;
    } catch (error) {
      console.error('Ocurrio un error: ' + error);
    }
    return false;
  }

  // Imagenes de la venta
  async putImg(id: number, body: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.put(`/sales_document/${id}`, body);
      return resp.data;
    } catch (error) {
      console.error('Error de peticion ' + error);
    }
    return false;
  }

  async put(id: number, body: any): Promise<any> {
    try {
      const data = await this.databaseService.put(`/sales/${id}`, body);
      return data.data;
    } catch (error) {
      return [];
    }
  }

  // Actualiza datos de la venta
  async putSale(body: any): Promise<any> {
    try {
      const data = await this.databaseService.put(`/sales/seller`, body);
      return data.data;
    } catch (error) {
      return [];
    }
  }

  // Actualiza plan de pago de la venta
  async putPaymentPlan(idSales: number, payment_plan: number): Promise<any> {
    try {
      const data = await this.databaseService.put(`/sales/payment_plan`, { idSales, payment_plan });
      return data;
    } catch (error) {
      return [];
    }
  }

  // Verifica la venta
  async putVerified(verified: any, body: any): Promise<any> {
    try {
      const data = await this.databaseService.put(`/sales/verified/${verified}`, body);
      return data.data;
    } catch (error) {
      return [];
    }
  }

  // Libera la venta
  async putRealse(body: any): Promise<any> {
    try {
      const data = await this.databaseService.put(`/realse/sale/`, body);
      return data.data;
    } catch (error) {
      return [];
    }
  }

  async putCancel(body: any): Promise<any> {
    try {
      const data = await this.databaseService.put(`/cancel/sale/`, body);
      return data.data;
    } catch (error) {
      return [];
    }
  }

  async getReturnSupervisor(id: number, day: any): Promise<any> {
    const end = moment(day, "YYYY-MM-DD").add(1, 'days').format("YYYY-MM-DD");
    let params = { id: id, start: day, end: end };
    const data = await this.databaseService.getWithParams(`/returns/owe`, params);
    return data.data;
  }

  async createPaymentLinkOxxo(saleId: number, amount: number): Promise<any> {
    const data = await this.databaseService.get(`/app/administrative/create/session/${saleId}/${amount}`);
    return data.data;
  }

  async createPaymentLinkOpenpay(saleId: number, amount: number): Promise<any> {
    const data = await this.databaseService.get(`/app/administrative/create/session/openpay/${saleId}/${amount}`);
    return data.data;
  }

  async findActiveSales(): Promise<any> {
    const data = await this.databaseService.get(`/app/storer/available-sales`);
    return data.data;
  }

  async getOxxoReferenceData(ref: string): Promise<OxxoReferenceModel> {
    const data = await this.databaseService.get(`/stripe/find/oxxo-reference/${ref}`);
    return data.data;
  }

  async getTrackerMapSales(): Promise<TrackerMapSaleInterface[]> {
    const data = await this.databaseService.get(`/tracker/active-sales`);
    return data.data;
  }
}
