import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DatabaseService } from '../database/database.service';
import { PermissionsService } from '../trabajador/permissions.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService{

  public loggedIn = false;
  public user:any[] = [];
  public menu:any[] = [];

  constructor(
    private router: Router,
    private databaseService: DatabaseService,
    private permissionsService:PermissionsService
    ){
      
    if(localStorage.getItem('token') === null || localStorage.getItem('token') === '') {
  //  if(false){
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
  }

  async login(data: any): Promise<any>{
    
    const resp = await this.databaseService.post(`/login`, data);
    
    return resp;
  }

  logout(): void{
    localStorage.setItem('token','');
    this.loggedIn = false;
    this.router.navigateByUrl('/login');
  }
}