import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { DatabaseService } from '../database/database.service';
import { configWs } from 'src/app/app.module';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService extends Socket{

  public socketStatus = false;
  public usuario = null;

  constructor(private socket: Socket, private databaseService: DatabaseService) {
    super(configWs);
    this.checkStatus();
  }

  checkStatus() {
  
    this.socket.on('connect', () => {
      
      this.socketStatus = true;

      this.socket.emit('register-user', {
        'database': -1,
        'name': localStorage.getItem('name') ?? 'Panel administrador',
        'surname': localStorage.getItem('surname') ?? 'No aplica',
        'type': 0,
      });
    });

    this.socket.on('disconnect', () => {
      
      this.socketStatus = false;
    });
  }

  emit(evento: string, payload?: any, callback?: Function) {
    this.socket.emit(evento, payload, callback);
  }

  listen(evento: string) {
    return this.socket.fromEvent(evento);
  }

  getUserType(type: number) {
    if (type == 1) {
      return 'USUARIO';
    } else if (type == 2) {
      return 'AFILIADO';
    } else if (type == 0) {
      return 'CONTROL';
    } else {
      return 'DESCONOCIDO';
    }
  }
}
