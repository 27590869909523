import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';

import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { TrabajadorComponent } from './views/pages/trabajador/trabajador.component';
import { SucursalesComponent } from './views/pages/sucursales/sucursales.component';
import { GroupsComponent } from './views/pages/group/group.component';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { HtmlInterceptor } from './interceptor/html.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorPickerModule } from 'ngx-color-picker';
import { sistemasComponent } from './views/pages/sistemas/sistemas.component';
import { environment } from 'src/environments/environment';

// Idioma
import { LOCALE_ID } from "@angular/core";
import localeEs from "@angular/common/locales/es";
import { registerLocaleData } from "@angular/common";
import { ChartsModule } from 'ng2-charts';
import { TwilioComponent } from './views/pages/twilio/twilio.component';
import { AutomaticPayrollPipe } from './pipes/automatic-payroll.pipe';
import { TimeElapsedPipe } from './views/pages/pipes/time-elapsed.pipe';
import { PipesModule } from './views/pages/pipes/pipes.module';
import { CustomErrorHandler } from './services/custom-error-handler.service';
import { DelaySalesTableDirective } from './views/pages/directives/tab-traces-payments.directive';



registerLocaleData(localeEs, "es");


export const configWs: SocketIoConfig = { url: environment.ws };
export const configGpsWs: SocketIoConfig = { url: environment.gpsWs,  options: {
  transports: ['websocket'],
  withCredentials: false,  // Configurar si el servidor lo permite
  extraHeaders: {
    'Access-Control-Allow-Origin': environment.baseUrl,
  }
} };

@NgModule({
  declarations: [
    DelaySalesTableDirective,
    AppComponent,
    ErrorPageComponent,
    TrabajadorComponent,
    SucursalesComponent,
    GroupsComponent,
    sistemasComponent,
    TwilioComponent,
    // TimeElapsedPipe,
  ],
  imports: [
    ColorPickerModule,
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    SocketIoModule.forRoot(configWs),
    SocketIoModule.forRoot(configGpsWs),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es" },
    { provide: HTTP_INTERCEPTORS, useClass: HtmlInterceptor, multi: true },
    AuthGuard,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    },
    // environment.production ? { provide: ErrorHandler, useClass: CustomErrorHandler } : [],
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
