import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../services/login/auth.service';
import Swal from 'sweetalert2';
import { toast } from 'src/app/functions/notifications';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, AfterViewInit {


  @Output() isChat = new EventEmitter<boolean>();

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  sessionId = localStorage.getItem('sessionId');

  menuItemSwiss: MenuItem[] = JSON.parse(localStorage.getItem('menuArbolSwiss')) || []; // Menu desde localStorage
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private renderer: Renderer2,
    router: Router,
    public authService: AuthService
  ) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  async ngOnInit() {
    if (this.menuItemSwiss.length == 0) {
      toast('Sucedio un error inicia secion de nuevo', 'error');
    }

    //colocar iconos
    this.menuItemSwiss.forEach(menuIcon => {
      if (menuIcon.id == 6) { //sucursales
        menuIcon.icon = 'map-pin'
      }
      if (menuIcon.id == 11) { //clientes
        menuIcon.icon = 'user'
      }
      if (menuIcon.id == 14) { //Vehiculos
        menuIcon.icon = 'truck'
      }
      if (menuIcon.id == 17) { //Productos
        menuIcon.icon = 'archive'
      }
      if (menuIcon.id == 20) { //Sistemas
        menuIcon.icon = 'cpu'
      }
      if (menuIcon.id == 23) { //Empleados
        menuIcon.icon = 'users'
      }
      if (menuIcon.id == 27) { //Ventas
        menuIcon.icon = 'shopping-bag'
      }
      if (menuIcon.id == 30) { //Almacen
        menuIcon.icon = 'layers'
      }
      if (menuIcon.id == 40) { //cobranza
        menuIcon.icon = 'dollar-sign'
      }
      if (menuIcon.id == 52) { //Administrativo
        menuIcon.icon = 'folder'
      }
      if (menuIcon.id == 196) { // Franquicia
        menuIcon.icon = 'activity'
      }
      if (menuIcon.id == 84) { //Monitoreo
        menuIcon.icon = 'monitor'
        //externo
        menuIcon.subItems[0].external = true;
      }
      if (menuIcon.id == 212) { //Inicio
        menuIcon.icon = 'book'
      }

    })

    // this.menuItemSwiss.unshift({id:100,label:'Inicio',icon:'home',link:'/dashboard'});

    // this.menuItems = MENU;
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
    this.onSidebarThemeChange('sidebar-dark');


  }

  //    Recursivo (menu: MenuInterface): MenuInterface | null {
  //     const menuRef = this.menuN.find(m => m.id == menu.menuId);
  //     if (menuRef.menuId === null) {
  //       return menuRef;
  //     } else {
  //       return this.Recursivo(menuRef);

  //     }
  // }

  async ngAfterViewInit() {
    // activate menu item

    // const espera = new Promise((resolve, reject) => {
    //   setTimeout(() => {
    //     resolve(true);
    //   }, 2000);
    // });

    // await espera;

    new MetisMenu(this.sidebarMenu.nativeElement);
    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    // this.document.body.classList.add(event.target.value);
    this.document.body.classList.add(event);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {

        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };


  onChat(resp: boolean) {
    this.isChat.emit(resp);
  }

  isThisChatSubItems(item: any) {
    //5 = Monitoreo
    if (item.id == 5) {
      this.onChat(true)
    } else {
      this.onChat(false)
    }
  }

  isThisChatItems(item: any) {

    //5 = Monitoreo
    if (item.id == 5 || 100) {
      this.onChat(true)
    } else {
      this.onChat(false)
    }
  }
}
