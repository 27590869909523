import { Directive, Input, OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { DalaysSalesService } from 'src/app/services/analysis/delays.service';

@Directive({
  selector: '[appDelaySalesTable]',
})

export class DelaySalesTableDirective implements OnInit, AfterViewInit {
  @Input() saleId: number;

  constructor(
    private delaysSalesService: DalaysSalesService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}
  
  ngAfterViewInit(): void {
    throw new Error('Method not implemented.');
  }

  async ngOnInit() {
    console.log("entro a la directiva");
    this.AfterViewInit();
  }

   async AfterViewInit() {
    this.saleId = 865;
    console.log("entro a la directiva");
    const data = await this.delaysSalesService.getTabDelaysSale(this.saleId);

    const tableDiv = this.renderer.createElement('div');
    this.renderer.addClass(tableDiv, 'table-responsive');
    this.renderer.addClass(tableDiv, 'pt-3');
    this.renderer.addClass(tableDiv, 'contenedor');
    this.renderer.setStyle(tableDiv, 'overflow-x', 'scroll');
    this.renderer.setStyle(tableDiv, 'scroll-behavior', 'smooth');
    this.renderer.setStyle(tableDiv, 'padding-top', '0rem');
    this.renderer.setStyle(tableDiv, 'margin-top', '0px');
    this.renderer.setStyle(tableDiv, 'max-width', '500px');

    const table = this.renderer.createElement('table');
    const row = this.renderer.createElement('tr');

    for (const t of data[0].tab) {
      const cell = this.renderer.createElement('td');
      this.renderer.setStyle(cell, 'background-color', t.color);
      this.renderer.setAttribute(cell, 'title', t.payment_date);
      this.renderer.appendChild(cell, this.renderer.createText(`${t.data}`));
      this.renderer.appendChild(row, cell);
    }

    const totalCell = this.renderer.createElement('td');
    this.renderer.setStyle(totalCell, 'background-color', '#FFFFFF');
    this.renderer.setStyle(totalCell, 'color', '#000000');
    this.renderer.setStyle(totalCell, 'width', '110px');
    this.renderer.setStyle(totalCell, 'height', '10px');
    this.renderer.appendChild(totalCell, this.renderer.createText(`$${data[0].total_atrazo}`));
    this.renderer.appendChild(row, totalCell);

    this.renderer.appendChild(table, row);
    this.renderer.appendChild(tableDiv, table);
    this.renderer.appendChild(this.el.nativeElement, tableDiv);
  }
}
