import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  public url = environment.baseUrl;

  constructor(
    private https: HttpClient,
  ) {
    // this.url = this.routeUrl();
  }

  get(route: string, params?: any): Promise<{ ok: boolean, data: any }> {
    return this.https.get(`${this.url}${route}`, { params }).toPromise<any>();
  }

  getFiles(route: string, params?: any): Promise<{ ok: boolean, data: any }>{
    const options = {
      params,
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'blob' as 'json'  // This line specifies the response type
    };
    return this.https.get<Blob>(`${this.url}${route}`, options).toPromise<any>();
  }

  getAndCount(route?: string, limite?: number, desde?: number, f1?: string, f2?: string, query?: boolean): Promise<{ ok: boolean, data: any, count: number }> {
    // Si la ruta necesita parametros por query
    if (query == true) {
      return this.https.get(`${this.url}${route}`).toPromise<any>();
    } else {
      if (f1 && f2) {
        return this.https.get(`${this.url}${route}?page=${desde}&limit=${limite}&f1=${f1}&f2=${f2}`).toPromise<any>();
      } else {
        return this.https.get(`${this.url}${route}?page=${desde}&limit=${limite}`).toPromise<any>();
      }
    }

  }

  getWithParams(route: string, params?): Promise<{ ok: boolean, data: any }> {
    if (params) {
      return this.https.get(`${this.url}${route}`, {
        params
      }).toPromise<any>();
    } else {
      return this.https.get(`${this.url}${route}`).toPromise<any>();
    }
  }

  async post(route: string, data: any): Promise<{ ok: boolean, data: any }> {
    return this.https.post(`${this.url}${route}`, data).toPromise<any>();
  }

  put(route: string, data: any): Promise<{ ok: boolean, data: any }> {
    return this.https.put(`${this.url}${route}`, data).toPromise<any>();
  }

  patch(route: string, data: any): Promise<{ ok: boolean, data: any }> {
    return this.https.patch(`${this.url}${route}`, data).toPromise<any>();
  }

  delete(route: string): Promise<{ ok: boolean, data: any }> {
    return this.https.delete(`${this.url}${route}`).toPromise<any>();
  }

}
