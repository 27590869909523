import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { toast } from '../functions/notifications';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable()
export class HtmlInterceptor implements HttpInterceptor {

  private PDF_SERVICE_URL = environment.pdfServiceBaseurl;

  constructor(
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const token = localStorage.getItem('token'); // Token de la sesión
    const version_patch: string = '3';
    const appId: string = '7';

    if (req.url.includes('https://api.cloudinary.com')) {
      return next.handle(req).pipe(
        catchError(this.interceptError)
      );
    }

     // Excepción para otra URL
    if (req.url.includes(this.PDF_SERVICE_URL)) {
      return next.handle(req);  // No se aplican headers o modificaciones a esta petición
    }

    let headers = new HttpHeaders({
      version_patch: version_patch,
      appId: appId,
    });

    if (token == null || token == undefined || token == '') {
      return next.handle(req.clone({ headers }));
    }

    headers = new HttpHeaders({
      version_patch: version_patch,
      appId: appId,
      token,
    });

    const reqCopy = req.clone({
      headers
    });

    return next.handle(reqCopy).pipe(
      catchError(this.interceptError)
    );
  }

  interceptError(err: HttpErrorResponse): Observable<HttpEvent<any>> {
    console.error(err);
    if (err.status === 401) {
      toast('Acceso restringido', 'error');

      localStorage.removeItem('token');
      localStorage.removeItem('sessionId');
      localStorage.removeItem('name');
      localStorage.removeItem('surname');
      localStorage.removeItem('email');
      localStorage.removeItem('photo');
      localStorage.removeItem('menuArbolSwiss');

      setTimeout(() => {
        window.location.href = "/auth/login";
      }, 2000);

    } else if (err.status == 400) {
      toast('Petición invalida', 'error');
    }

    return throwError('Failed');
  }
}
