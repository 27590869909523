import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/login/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  sessionId = localStorage.getItem('sessionId');

  constructor(
    private authservice: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

      if (this.authservice.loggedIn) { return true }

    this.router.navigate(['/auth/login'], {
      queryParams: {
        returnUrl: state.url
      }
    });

    return false;

  }

}