import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebsocketService } from '../../../services/websockets/websocket.service';
import { IssuesInterface, MessagesInterface } from '../../../models/chat/issues.model';
import { ContractsInterface } from '../../../models/servicios/contratos-de-servicios.model';
import { ReportService } from '../../../services/sistemas/servicio.service';
import { differenceInHours, isToday, parse, parseISO, parseJSON } from 'date-fns';
import addDays from 'date-fns/addDays';
import Swal from 'sweetalert2';
import { ActivateSaleService } from '../../../services/monitoring/activate-sale.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
  hasInternetConnection: boolean = false;
  status: boolean = false;
  private interval: any; // Variable para almacenar el intervalo

  name=localStorage.getItem('name');
  workareaId = localStorage.getItem('workarea');
  email=localStorage.getItem('email');
  sessionId=localStorage.getItem('sessionId');
  workareaName=localStorage.getItem('workareaName');
  photo=localStorage.getItem('photo');
  token=localStorage.getItem('token');

  suscriptionMessage: Subscription | undefined;
  issues: IssuesInterface[] = [];
  notification = false;

  currentlyContracts: ContractsInterface[] = [];
  pendingContracts: ContractsInterface[] = [];
  waitingContracts: ContractsInterface[] = [];
  login_intention_custom:LoginIntentionCustom[] = [];

  suscriptionReadMessage: Subscription | undefined;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private websocketService:WebsocketService,
    private activateSaleService:ActivateSaleService

  ) { }

  async ngOnInit(): Promise<void> {
    //Nuevos contratos
    let allContracts:ContractsInterface[] = [];

    //Mensajes de otros paneles o leidos por el usuario
    this.suscriptionReadMessage = this.websocketService.listen(('support-send-message')).subscribe(async (e:any)=>{
      //
     // this.selectChat(e.issue)

      const issue = this.issues.find(i => i.id == e.issue.id)
      if (issue) {
        //para que responda soporte el problema ya se creo anteriormente
        const newMessage: MessagesInterface = {
          id: -1,
          isSupport: false,
          createdAt: e.issue.createdAt,
          message: e.issue.messages[e.issue.messages.length-1].message,
          updatedAt: e.issue.createdAt,
          read:false,
          employeeId: +this.sessionId
        }
        issue.messages.push(newMessage);
      }
      // this.reproducir();
       this.notification = true;
        //
    })

    // Login Intentio
    const resp = await this.activateSaleService.getLoginIntentions();
    if (resp.ok){
      this.login_intention_custom = resp.data
    }

    this.interval = await setInterval(() => {
      this.checkNetworkStatus(); // Revisa la conexion
    }, 5000);
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**Logout*/
  onLogout(e) {
    e.preventDefault();
    localStorage.removeItem('token');
    localStorage.removeItem('sessionId');
    localStorage.removeItem('name');
    localStorage.removeItem('surname');
    localStorage.removeItem('email');
    localStorage.removeItem('photo');
    localStorage.removeItem('menuArbolSwiss');
    // localStorage.setItem('isLoggedin','false');

    // if (!localStorage.getItem('isLoggedin')) {
    //   this.router.navigate(['/auth/login']);
    // }
    this.router.navigate(['/auth/login']);
  }

  reproducir(){
    const audio = new Audio('assets/audio/Tejat.mp3');
    audio.play();
  }

  obtenerDias(dias:string):string{
    let diasSemana = ''
    const diasSeparados = dias.split(',')
    diasSeparados.forEach(d => {
      if (d === '0' ){ diasSemana = diasSemana + ' Domingo ' }
      if (d === '1' ){ diasSemana = diasSemana + ' Lunes ' }
      if (d === '2' ){ diasSemana = diasSemana + ' Martes ' }
      if (d === '3' ){ diasSemana = diasSemana + ' Miercoles ' }
      if (d === '4' ){ diasSemana = diasSemana + ' Jueves ' }
      if (d === '5' ){ diasSemana = diasSemana + ' Viernes ' }
      if (d === '6' ){ diasSemana = diasSemana + ' Sabado ' }
    });
    return diasSemana
  }

  // Comprueba la conexión a internet
  async checkNetworkStatus() {
    this.hasInternetConnection = navigator.onLine;

    if(this.hasInternetConnection && this.status == true){}
}

}


export interface LoginIntentionCustom {
  id:        number;
  model:     string;
  createdAt: Date;
  count:     number;
  client:    Client;
  employee:  Client;
}

export interface Client {
  id:      number;
  name:    string;
  surname: string;
}
