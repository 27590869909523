import { Injectable } from '@angular/core';
import { ContractsInterface } from 'src/app/models/servicios/contratos-de-servicios.model';
import { ServicioInterface } from '../../models/servicios/services.model';
import { DatabaseService } from '../database/database.service';
import { Log } from '../../models/servicios/contratos-de-servicios.model';
import { HttpClient } from '@angular/common/http';
import { ServicesContracts } from 'src/app/models/servicios/servicios-contratos.model';
import { TimeContractServInterface } from 'src/app/models/servicios/time-contracts-servicio.model';
import { LogServiceInferface } from 'src/app/models/servicios/logs-servicios.model';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private databaseService: DatabaseService,private http:HttpClient) { }

  async getAll(): Promise<ServicioInterface[]> {
    const data = await this.databaseService.get('/services');
    return data.data;
  }
  

  async getContractsAll(): Promise<ContractsInterface[]> {
    const data = await this.databaseService.get('/contracts');
    return data.data;
  }
  async getServicesOfContractsAll(): Promise<ServicesContracts[]> {
    const data = await this.databaseService.get('/contracts/services/c');
    return data.data;
  }
  async getServicesContractsAll(): Promise<ContractsInterface[]> {
    const data = await this.databaseService.get('/contracts');
    return data.data;
  }

  async getContractsAllCount(): Promise<ContractsInterface[]> {
    const data = await this.databaseService.get('/contracts/all/count');
    return data.data;
  }

  async getContractsAllCountGraph(start?:string, end?:string): Promise<ContractsInterface[]> {
    if (start && end){
      let  params = {start, end};
      const data = await this.databaseService.getWithParams('/contracts/all/graph/date',params);
      return data.data;
    }else{
      const data = await this.databaseService.getWithParams('/contracts/all/graph/date');
      return data.data;
    }
  }

  async getSelectCotract(id: any): Promise<ContractsInterface> {
    const data = await this.databaseService.get(`/contracts/${id}`);
    return data.data;
  }
  async postStarService(data: any): Promise<boolean>{
    // try {} catch (error) {
    //   console.error('Error de peticion '+error);
    // }
      const resp = await this.databaseService.post('/contracts/start', data);
      return resp.data;
    
  }

  async getCronogramaServ(): Promise<any[]> {
    const data = await this.databaseService.get('/contracts/cronograma/today');
    return data.data;
  }
  async getAllServices(): Promise<TimeContractServInterface[]> {
    const data = await this.databaseService.get('/contracts/services/all');
    return data.data;
  }

  async getService(id: string, date1:string, date2:string): Promise<ServicioInterface[]> {
    const data = await this.databaseService.get('/services/payroll/1/2019-07-29/2022-07-29');

    const servicios: any[] = [];

    data.data.forEach((e: any) => {
      const service = e.services;
      service.forEach((j: any) => {
        servicios.push(j);
      });
    });

    return servicios;
  }  
  async getReportService(date1:string, date2:string): Promise<ServicioInterface[]> {
    const data = await this.databaseService.get(`/services/report/${date1}/${date2}`);
    const servicios: any[] = [];

    data.data.forEach((e: any) => {
      const service = e.services;
      service.forEach((j: any) => {
        servicios.push(j);
      });
    });

    return servicios;
  }
  async getReportContracts(date1:string, date2:string): Promise<ContractsInterface[]> {
    const data = await this.databaseService.get(`/contracts/report/${date1}/${date2}`);
    const servicios: any[] = [];
    
    
    return data.data;
  }

  async getGraphReportContracts(date1:string, date2:string): Promise<ContractsInterface[]> {
    const data = await this.databaseService.get(`/contracts/graph/${date1}/${date2}`);
    return data.data;
  }
  
  async getFilterServices(date1:string, date2:string): Promise<any[]> {
    const data = await this.databaseService.get(`/contracts/cronograma/filters/${date1}/${date2}`);
    return data.data;
  }

  async findOne(id: number): Promise<ServicioInterface>{
    // const data = await this.databaseService.get(`/services`);
    const data = await this.databaseService.get(`/services/id/${id}`);
    return data.data;
  }

  async findOneContract(id: number): Promise<ContractsInterface>{
    // const data = await this.databaseService.get(`/services`);
    const data = await this.databaseService.get(`/contracts/${id}`);
    return data.data;
  }

  async resumeServiceInSOS(id: number) {
    const data = await this.databaseService.put(`/emergencies/resume/${id}`, {
      service: id
    });
    return data.ok;
  }

  async getAllLogs():Promise<Log[]>{
    const resp = await this.databaseService.get(`/logs/all`);
    return resp.data;
  }

  async getAllContracts():Promise<ContractsInterface[]>{
    const resp = await this.databaseService.get(`/contracts/all`);
    return resp.data;
  }

  async getAllContractsByDate(start?:string, end?:string):Promise<ContractsInterface[]>{
    if (start && end){
      let  params = {start, end};
      const resp = await this.databaseService.getWithParams(`/contracts/all/count/date`,params);
      return resp.data;
    }else{
      const resp = await this.databaseService.getWithParams(`/contracts/all/count/date`);
      return resp.data;
    }
  }

  async getAllContractsReplace():Promise<ContractsInterface[]>{
    const resp = await this.databaseService.get(`/contracts`);
    return resp.data;
  }

  getAllLogsSuscription(){
    return this.http.get(`${this.databaseService.url}/logs/all`)
  }

  getAllContractsSuscription(){
    return this.http.get(`${this.databaseService.url}/contracts/all/count`)
  }

  //----------------- acciones de servicios  ---------------

  async nextWeek(id: number,day: number) {
    
    const data = await this.databaseService.put(`/contracts/cronograma/nextWeek/${id}/${day}`, { });
    
    return data;
  }
  async cancelService(id: number) {
    const data = await this.databaseService.put(`/contracts/revoke/${id}`, {});
    return data;

  }async changeDya(data: any) {
    const update = await this.databaseService.put(`/contracts/changeDay/`, {data});
    return data;
  }


}