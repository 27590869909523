import { Injectable } from '@angular/core';
import { DatabaseService } from '../database/database.service';
import { DirectionsInterface } from '../../models/clientes/direcctions.model';

@Injectable({
  providedIn: 'root'
})
export class DirectionService {

  constructor(private databaseService: DatabaseService) { }

  async getAll(): Promise<DirectionsInterface[]> {
    const dir = await this.databaseService.get(`/directions`);
    return dir.data;
  }

  // Obtiene todas las direcciones del cliente
  async getAllClientDirections(id: any): Promise<DirectionsInterface[]> {
    const dir = await this.databaseService.get(`/directions/user/${id}`);
    return dir.data;
  }

  async getDirection(id: number): Promise<DirectionsInterface> {
    const dir = await this.databaseService.get(`/direction/${id}`);
    return dir.data;
  }

  async findOne(id: number): Promise<DirectionsInterface> {
    const resp = await this.databaseService.get(`/directions/${id}`);
    return resp.data;
  }

  async findmain(id: number): Promise<DirectionsInterface> {
    const resp = await this.databaseService.get(`/directions/main/${id}`);
    return resp.data;
  }

  // Insertar dirección
  async post(data: any): Promise<boolean> {
    try {
      const resp = await this.databaseService.post('/direction', data);
      return resp.data;
    } catch (error) {
      console.error('Error de peticion ' + error);
    }
    return false;
  }

  async put(id: number, data: any): Promise<boolean> {
    const resp = await this.databaseService.put(`/directions/id/${id}`, data);
    return resp.ok;
  }
}